@import "~bootstrap/scss/bootstrap";
@import "./_constants.scss";

.top-navigation
{
	padding:24px;
	position: fixed;
	z-index: $top-nav-zindex;
	top:0;
	left:0;
	width: 100%;
	@include media-breakpoint-down(sm) {

		padding:10px;	
	}


	.user-menu
	{


		.dropdown-menu 
		{
			border: 2px solid #D9D9D9;
			min-width: 334px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
			
			.user-profile
			{
				padding-left:16px;
				padding-right:16px;
				display: flex;
				flex-direction: column;
				align-items: center;
				row-gap: 16px;
				img
				{
					width: 64px;
					height: 64px;
					border-radius: 32px;
				}
				h3
				{
					padding:0;
				}
			}
		}
		/* overrides bootstrap dropdown.toggle	*/
		.user-menu-toggle
		{ 
			display: block;
			border-radius: 24px;
			width: 48px;
			height: 48px;
			overflow: hidden;
			padding: 0;
			border:none;

			&, &[disabled], &:hover, &:active, &:focus
			{
				background-color: $text-color-secondary;
			}
			&:after
			{
				content: none;
			}

			@include media-breakpoint-down(sm) {
		       border-radius: 12px;
				width: 24px;
				height: 24px;
	        }


			img
			{
				width: 100%;
				height: 100%;
			}

			svg
			{
				position: absolute;
				top:50%;
				left: 50%;
				transform: translate(-50%,-50%);
				@include media-breakpoint-down(sm) {
					width: 12px;
					height: 12px;
				}
			}

		}
	}


	&.menu-group, .menu-group
	{
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		column-gap: 44px;
		@include media-breakpoint-down(sm) {
			column-gap: 24px;
		}
		a.menu-item
		{
			color: #FFFFFF;
/*			color:red;*/
			text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
			position: relative;
			@include media-breakpoint-down(sm) {
				font-size:14px;
			}
		/*	-webkit-text-stroke: 1px  #1E1E1E;
			text-stroke: 1px #1E1E1E;*/
			@include media-breakpoint-down(sm) {
				svg
				{
					width:24px;
					height: 24px;
				}
			}

			@include media-breakpoint-down(sm) {
				&.pineappel-icon svg
				{
					width:24px;
					height:24px;
				}

			}
			@include media-breakpoint-up(sm) {
				&.pineappel-icon svg
				{
					width:48px;
					height:48px;
				}

			}

			&.active:after
			{
				position: absolute;
				content:"";
				background-color:#FFFFFF;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
				width:60%px;
				height: 3px;
				bottom: -12px;
				width: 30px;
				left: 50%;
				transform: translateX(-50%);
				-webkit-text-stroke: 1px  #1E1E1E;
				text-stroke: 1px #1E1E1E;

				@include media-breakpoint-down(sm) {
					bottom: -8px;
				}
			}


			
		}
	}


}
