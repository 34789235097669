@import "./_constants.scss";

.flash-message
{
	

	z-index: $flash-message-zindex;
	position: fixed;
	padding:16px 20px;
	min-width: 50px;
/*	border:2px solid red;*/
	margin-left:50%;
	background-color: rgba(0,0,0,0.8);
	
	transform: translateX(-50%);
	left: 0;
	opacity: 0;
	top:80px;	
	border-radius: 8px;
	transition: opacity 0.5s;

	&.show
	{
		opacity: 1;
		
	}

	i
	{
		padding-right: 12px;
	}
	.message
	{
		color: white;

		font-weight: 600;
		font-size: 16px;
		line-height: 20px;



	}


}