.enumerated-checkbox
{
	
	height: 24px;
	width: 24px;
	border-radius: 12px;
	border:2px solid black;
	background-color: rgba(255, 255, 255, 0.5);
	position: absolute;
	right: 6px;
	top:6px;
	text-align: center;
	line-height: 20px;
	font-size: 14px;
	cursor: pointer;

	&.selected
	{
		background-color: #FE505B;
		border:none;
		line-height: 24px;
	}
	span
	{
		color:white;
	}

/*	border:1px solid red;*/

	
}