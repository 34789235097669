@import "https://fonts.googleapis.com/css?family=Montserrat";
/*@import "~@flaticon/flaticon-uicons/css/all/all";*/
@import "~bootstrap/scss/bootstrap";

@import "./components-css/_constants.scss";



/*
  Base styles from design doc
*/



/*
text styles
*/

/*
.montserrat-<uniquifier> {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}
*/




body, div, span, a, ul, li, input, form, textarea, button
{

  font-family: $font-family, sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.5px;
  color: $text-color-primary;
  font-style: normal;
}
* {-webkit-font-smoothing: subpixel-antialiased;}

body
{
    padding-bottom: 100px; /*  to account for the bottom menu */
    @include media-breakpoint-down(sm) {
        overflow: hidden;
    }


}



/*This is a helper class to be used in combination with body {overflow:hidden} on mobile*/
.scrollable
{
  @include media-breakpoint-down(sm) {
/*    border:2px solid red;*/
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    height: calc(100vh - 128px - 40px);
    overflow-y: scroll;

  }

}

input, textarea
{
  &:active, &:focus
  {
    outline:none;
  }
}

button
{
  background:none;
  border:none;
}


.debug, .debug-info
{

  position: absolute;
  top:0;
  left:0;
  padding:0.5em;
  background-color: lightgreen;
  color:black;
  font-size: 5px;
  font-family: Courier;
  z-index: $debug-zindex;
}
body {
  
  .container.full-width
  {
    @include media-breakpoint-down(sm) {
       & {
          padding-left: 0;
          padding-right: 0;
          }
        }
  }

  .grid-view, .home-page
  {
/*    padding-top: 29px;*/
  }

  a, a:active, a:hover, a:visited, a:active
  {
    color: inherit;
    text-decoration: none;
  }

  .align-center
  {
      text-align: center;

  }


  .small-modal.modal-dialog
    {
        top:135px;
        overflow: visible; /*required for dropdowns to be fully visible*/
        border:none;
        width: 412px;
        max-width: none !important;
       


       @include media-breakpoint-down(sm) {
       & {
           position: absolute;
           z-index:$modal-zindex;
/*           border:2px solid red;*/
            width: 100%;
            margin-left:auto;
            margin-right:auto;
            top: unset;
            bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0
          }
        }



        .modal-body
        {
          padding-left:40px;
          padding-right: 40px;
          padding-top:0;
          padding-bottom:0;
        }


        .modal-header 
        {
          
          border-bottom: none;
          padding-left:40px;
          padding-right: 40px;
          padding-bottom: 20px;
          padding-top: 40px;
          position: relative;
          display: flex;
          justify-content: center;
          h3{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #1E1E1E;
          }
        

          button.btn-close
          {
          
            position: absolute;
            right:20px;
            top:20px;
            background-image: none;
            background-color: #F1F1F2;
            margin: 0;
            padding:0;
            width: 32px;
            height: 32px;
            border-radius: 16px;
            @include media-breakpoint-down(sm) {
              right:16px;
              top:16px;

            }

          }
        }

        .modal-footer
        {
          border-top:none;
          padding-left:40px;
          padding-right: 40px;
          padding-bottom: 28px;
          padding-top: 28px;

          @include media-breakpoint-down(sm) {
            & {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
/*              border:3px solid red;*/
            }
          }
        }


        @include media-breakpoint-down(sm) {
            & .modal-body, 
            & .modal-header, 
            & .modal-footer
            {
/*              border:3px solid red;*/
              padding-left:16px;
              padding-right: 16px;
            }
        }

        

    }

    

  .text-strong
  {
    font-weight: 600;
  } 

  .text-medium
  {
    font-size: 14px;
    line-height: 16px;
    color: $text-color-secondary;
  }

  .text-small
  {
    font-size: 12px;
    line-height: 16px;
    color: $text-color-primary;
  }

  .text-xsmall
  {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: $text-color-primary;
  }

  








  h1, .h1{
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    padding-bottom: 25px;
    @include media-breakpoint-up(sm) {
          & {
            
font-size: 42px;
    
    line-height: 52px;
          }
        }
  }


  h2, .h2{
  font-size: 28px;
  line-height: 40px;
    text-align: left;

    @include media-breakpoint-up(sm) {
       & {
      font-size: 24px;
      line-height: 32px;

          }
        }
  }


  .mobile-only
  {
    @include media-breakpoint-up(md)
    {
      display: none ;
    }
    @include media-breakpoint-down(md) 
    {
        display: inline;
    }
  }

  .desktop-only
  { 
    @include media-breakpoint-down(md)
    {
      display: none ;
    }
    @include media-breakpoint-up(md) 
    {
         display: inline;
    }
  }



  h3, .h3{
  font-size: 20px;
  line-height: 24px;
/*    text-align: left;*/
  font-weight: 600;
    @include media-breakpoint-up(sm) {
       & {
      font-size: 18px;
      line-height: 24px;

          }
        }
  }

  .button
  {
    
    &:hover
    {
      background-color: #F8F8F8;
    }

    &:focus
    {
      border-width:2px;
    }

    &:active
    {
      background: #D9D9D9;
    }
    
    font-family: $font-family;
    border:1px solid #bfbfbf;
    line-height: 34px;
    font-size: 18px;
    border-radius: 8px;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  /*  padding: 20px 12px;*/
    background-color: white;



    &.full-width
    {
      width: 100%;
    }
    &.button-primary
    {
      &:hover
      {
        background-color: #FE767F;
      }
      &:active
      {
        background: #EE505A;
      }
      background-color: $brand-color-primary;
      color:white;
      border-radius: 8px;
    }


    &.roundButt
    {
      text-align: center;
      padding: 0;
      border:none;
      width: 60px;
      height: 60px;
      border-radius: 30px;
/*      background-color: #F1F1F2;*/

      &.active
      {
        background-color: $brand-color-primary;
        &:hover
        {
          background-color: #FE767F;
        }
        &:active
        {
          background: #EE505A;
        }
      }


    }
    @include media-breakpoint-up(sm) {
       & {
      font-size: 16px;
      line-height: 19px;

          }
        }

  }

  /*input[type="text"]
  {
    border-radius: 8px;
    background-color: $fill-color-primary;
    padding: 10px 16px ;
    justify: space-between;
    opacity: 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;

  }*/

  a, a:active, a:hover
  {
    text-decoration: none;
  }
}


/*
  misc things (remove/refactor)
*/

.hidden
{
  display: none !important;
}



@keyframes bg_animation1 {
  50% {background-size: 125% auto;}
}
@keyframes bg_animation2 {
  50% {
      background-size: 200% auto;
      background-position: 40% 40%;
  }
}

@keyframes bg_animation3 {
  50% {background-size: 125% auto;}
}





.spinner
{
  animation-duration: 1s;
  animation-name: Spinner;
  animation-iteration-count: infinite;
  animation-delay: 0s;
  display: block;
}

@keyframes Spinner {
    0%{
       transform: rotate(0deg);
/*       font-size: 1em;*/
    }
  
    100% {
       transform: rotate(360deg);
/*       font-size: 2em;/*/
    }

    
  }







.home-page 
{
  padding-bottom: 50px;
 

  .user-picture
  {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
}

/* crap for scrolling */



.my-container
{
  border:2px solid gray;
  width: 200px;
  height: 300px;
  overflow: scroll;
  margin-top:100px;
  a
  {
    border:1px solid black;
    
  }
  .content
  {
    height: 10000px;
    width: 200px;
    background-color: green;
    position: relative;
    .picky
    {
      display: block;
      position: absolute;
      left:34px;
      background-color: red;
      width: 10px;
      height: 10px;
    }
  }
}


