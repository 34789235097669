
.media-grid-wrapper
{
	position:relative;
	height:200px;
	width:100%;


	&.scroll-shadow
	{
		&:after
		{
			content:" ";
			position: absolute;
			height: 10px;
			bottom:0;
			left: 0;
			right: 0;
			background: linear-gradient(to bottom, rgba(#fff, 0), rgba(#ccc,1)  );
		}
	}
	

	.media-preview-grid
	{
		height: 100%;
		overflow: scroll;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap:12px;
		position: relative;
	/*	border:1px solid red;*/

		
	/*	border:1px solid red;*/
	/*	margin-right:-28px;*/
		.media-item-wrapper
		{
			
	/*		border:1px solid red;*/

	/*		border:1px solid green;*/
			/*column-gap: 6px;
			row-gap: 6px;*/
			
			


			.media-item
			{
				color:white;
	/*			width: 100%;*/
	/*			height: 100%;*/
				height: 116px;
				width:73px;
	/*			border:1px solid black;*/
				border-radius: 8px;	
				overflow: hidden;
				position: relative;
				

				background-color: #1E1E1E;
				.spinner-wrapper
				{
					position: absolute;
					top:50%;
					left: 50%;
					transform: translate(-50%,-50%);
					.spinner
					{
						width: 36px;
						height: 36px;
					}
				}


				img
				{
					position: absolute;
					top:50%;
					left:50%;
					transform: translate(-50%,-50%);
					
				}
				.label
				{
					/* Frame 1257 */
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis ;
					position: absolute;
					left: 3px;
					opacity: 0.5;
					bottom: 0;
					right: 3px;
					font-weight: 500;
					font-size: 10px;
					line-height: 16px;
					/* identical to box height, or 160% */

					color: #FFFFFF;

					text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);



				}
			}
		}
		
	}
}