@font-face {
  font-family: 'Pineappel';
  src:  url('./Pineappel.eot?lgr00g');
  src:  url('./Pineappel.eot?lgr00g#iefix') format('embedded-opentype'),
    url('./Pineappel.ttf?lgr00g') format('truetype'),
    url('./Pineappel.woff?lgr00g') format('woff'),
    url('./Pineappel.svg?lgr00g#Pineappel') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Pineappel' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-delete:before {
  content: "\e900";
}
.icon-download_for_offline:before {
  content: "\e901";
}
.icon-favorite:before {
  content: "\e902";
}
.icon-Frame-1263:before {
  content: "\e903";
}
.icon-Group:before {
  content: "\e904";
}
.icon-library_add:before {
  content: "\e905";
}
.icon-maps_ugc:before {
  content: "\e906";
}


.icon-1:before{
  content: "add files"
}
/*unicode="&#xe900;"
unicode="&#xe901;"
unicode="&#xe902;"
unicode="&#xe903;"
unicode="&#xe904;"
unicode="&#xe905;"
unicode="&#xe906;"
unicode=""
unicode="share"
unicode="like"
unicode="invite"
unicode="favorite"
unicode="download"
unicode="delete"
unicode="comment"
unicode="add users"
unicode="add more"
unicode="add files"
unicode="add"*/