@import "~bootstrap/scss/bootstrap";
.grid-view
{	
	display: flex;
	justify-content: center;
	padding-top:100px;

	.mid-row
	{

		.media-grid-wrapper
		{

			height:auto;
			&:after
			{
				display: none;
			}

			.media-preview-grid
			{
				overflow: visible;
/*				background-color: red;*/
				gap:16px;

	/*			border:1px solid red;*/
				max-width: 545px;
				width: 100%;
				justify-content: center;

				.media-item-wrapper
				{
					width: 170px;
					height: 260px;

					.media-preview-grid
					{

					}
					@include media-breakpoint-down(sm) {
						&{
							width: 106px;
							height: 160px;
						}

					}

					.media-item
					{
						width: 100%;
						height: 100%;
						.grid-item
						{
							position: relative;	
							width: 100%;
						    display: block;
						    height: 100%;
							img
							{
								width: 100%;
							}
	
						}
					}
					
				}
			}
		}

	}
	
}


.fweere
{

/*		width: ;*/
		.content
		{
			background-color:gray;
			width: 100%;
			height: 100%;
			border-radius: 4px;
			overflow: hidden;
			

			background-position: 50% 50%;
			background-size: cover;
			position: relative;
			.play
			{
				display: block;
				position: absolute;
				top:50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
}