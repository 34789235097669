
@import '_constants.scss';


.rich-textarea
{
/*	border:1px solid red;*/
	border-radius: 8px;
	background-color: $fill-color-primary;
	padding:16px;
	position:relative;
	width:100%;
	display:flex;
	flex-direction:column;
	margin-bottom:8px;


	textarea
	{ 
		resize: none;
		border:none;
		height:100px;
		width: 100%;
	}

	
}
