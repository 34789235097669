@import "~bootstrap/scss/bootstrap";
@import "./_constants.scss";


.avatar
{
	display:flex;
	flex-direction:column;
	align-items:center;
	position:relative;
	max-width:48px;
/*			overflow:hidden;*/
/*			text-overflow: ellipsis;*/
/*			white-space: nowrap;*/
	.avatar-image
	{
		width: 48px;
		height: 48px;
		overflow: hidden;
		border-radius: 24px;
		background-color:$fill-color-primary;
/*		position:relative;*/
		display: flex;
		flex-direction:row;
/*		justify-items:center;*/
		justify-content:center;
		align-items: center;
		font-weight:300;

		img
		{
			width: 100%;
		}

	}

	.initials
	{
/*		position: absolute;*/
		
		
/*		text-align: center;*/
/*		color:black;*/
/*		font-size:20px;*/
/*		padding:10px;*/
		
		
	}
	.remove
	{
/*				border:2px solid red;*/
		position: absolute;
		left:32px;
		top:1px;
		width: 16px;
		height: 16px;
		border-radius: 8px;
		padding: 0;
		background-color: $brand-color-primary;
		display:flex;
		flex-direction: row;
		justify-content:center;
		align-items:center;
		svg
		{
/*					position: absolute;*/
		}
	}
	.name
	{
		max-width: 64px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	
}