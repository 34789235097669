@import "~bootstrap/scss/bootstrap";

@import "./_constants.scss";




.landing-page
{
	
	/*	padding-top: 96px;*/
	

	&>div
	{
/*		border:1px solid red;*/
	}


	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: top;
	row-gap: 40px;
	height:100vh;
	

	.overlay
	{
		width: 496px;
		height: calc(100vh  - 68px);
/*		margin-top:12px;*/

		z-index: 100;
		background-color: rgba(0,0,0,0.5);
		border-radius: 12px;
		position: relative;
		@include media-breakpoint-down(sm) {
			display: none;
		}

		.content-actions ul li span
		{
			display: none;
		}
	}
	.background-video
	{
		position: absolute;
		overflow: hidden;
		left:0;
		right: 0;
		top:0;
		bottom: 0;
		video
		{
			width: 100%;
			height: 100%;
			object-fit: cover;

			&.large
			{
				display: block;
			}
			&.small
			{
				display: none;
			}
			@include media-breakpoint-down(sm) {

				&.large
				{
					display: none;
				}
				&.small
				{

					display: initial;

				}
			}
		}



	}

}





.modal.login-modal
{

	max-width: 100%;
/*	width:400px;*/
	

	.modal-dialog
	{
		@include media-breakpoint-down(sm) {
			margin-left:16px;
			margin-right:16px;

		}

		.modal-content
		{
			margin-left:12px;
			margin-right:12px;
			@include media-breakpoint-down(sm) {
				position: absolute;
				bottom:62px;
				margin-left:0px;
				margin-right:0px;
			}
/*			width:450px;*/

			
			.modal-body
			{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;


				padding:60px 40px;
				@include media-breakpoint-down(sm) {
					padding:40px 20px;
				}
			}
		}
		.hero-dialog
		{

			text-align: center;
			width: 100%;
			

			
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include media-breakpoint-down(sm) {
				column-gap: 14px;
			}
			

			.spinner-wrapper
			{
	/*			border:1px solid red;*/
				align-self: center;
				
			}

			h1
			{
				font-style: normal;
				font-weight: 700;
				font-size: 28px;
				line-height: 40px;
				@include media-breakpoint-down(sm) {
					font-weight: 700;
					font-size: 24px;
					line-height: 32px;
				}


				svg
				{
	/*				line-height: 96px;*/
					height: 70px;
					width: 70px;
		/*				margin-top: -30px;*/
					@include media-breakpoint-down(sm) 
					{
						height: 52px;
						width: 52px;
					}
				}
			}

			.google-login-button
			{
				border-radius: 26px;
				position: relative;
				padding-top: 14px;
				padding-bottom: 14px;
				svg
				{
					margin-right: 16px;
					margin-top:-4px;
				}
			}

			.nickname-form
			{
		/*			border:1px solid red;*/
				display: flex;
				flex-direction: column;
				width: 360px;
				max-width: 100%;
				margin: 0 auto;
				text-align: left;

				row-gap: 20px;


				.checkbox
				{
					display: flex;
					flex-direction: row;
					align-items: flex-start;

					.check
					{
						width: 24px;
						height: 24px;
						border: 2px solid black;
						border-radius: 12px;
						flex-shrink: 0;
						margin-right: 16px;
						cursor: pointer;
						display:flex;
						align-items:center;
						justify-content:center;
						&.checked
						{
							border:none;
							background-color: $brand-color-primary;
						}
					}
				}


			}
		}
	}
}
