
@import "./_constants.scss";

.spinner-wrapper{
/*  border:1px solid red;*/

  .spinner {
    width: 48px;
    height: 48px;
    display: block;
  /*  margin:15px auto;*/
    position: relative;
    color: #FFF;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
/*    border:1px solid red;*/

    &::after,&::before {
      content: '';  
      box-sizing: border-box;
      position: absolute;
      width: 50%;
      height: 50%;
      top: 50%;
      left: 50%;
      transform: scale(0.5) translate(0, 0);
/*      background-color: #FFF;*/
      background-color: #FFB700;
      border-radius: 50%;
      animation: animloader 1s infinite ease-in-out;
    }

    
    &::before {
/*      background-color: $brand-color-primary;*/
      background-color: #25D6DE;
      transform: scale(0.5) translate(-48px, -48px);
    }

    &.small::before {
/*      background-color: $brand-color-primary;*/
      background-color: #25D6DE;
      transform: scale(0.5) translate(-24px, -24px);
    }


  }

}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes animloader {
    50% {
      transform: scale(1) translate(-50%, -50%);
}
}

