.share-to-modal
{
	.select-all
	{
		position: absolute;
		left:20px;
		top:40px;
		

		a {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap:8px;
		}
		margin-right: auto;
		.enumerated-checkbox
		{
			position: initial;

		}
		
	}
	.list-container
	{
		margin-bottom: 20px;
		height: 200px;
/*		overflow: scroll;*/
	}

	.modal-footer
	{
		text-align: center;
		justify-content: center;
	}
}