.download_modal
{


	.select-all a 
	{
/*		border:1px solid red;*/
		display: flex;
		flex-direction: row;
		column-gap:8px;
		align-items: center;
	}
	.media-grid-wrapper
	{
		
		height: 100%;
		overflow: scroll;
		max-height:200px;

		.media-preview-grid
		{

			.media-item
			{
				.enumerated-checkbox
				{
					position: absolute;
					transform: none;
					top:6px;
					right:6px;
					span
					{
						color: white;
					}
				}
				position:relative;
				.faded
				{

					position: absolute;
					inset: 0;
					background-color: white;
					img
					{
						opacity: 0.2;
/*						display: none;*/
					}
				}
			}
		}
	}

	.modal-header{
		
		.select-all
		{
			position: absolute;
			left:20px;
			top:40px;
			
			display: flex;
			flex-direction: row;
			gap:5px;
			margin-right: auto;
			.enumerated-checkbox
			{
				position: initial;

			}
			a
			{
				line-height: 24px;
			}
		}
	}
	.modal-footer
	{
		.button-primary
		{
			width: 100%;
		}
	}
}