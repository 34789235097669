	
@import "./_constants.scss";
.form-input
{
	width:100%;
	position:relative;
	padding-bottom:20px;

	.indicator{
		position: absolute;
		right: 19px;
		top:17px;
		button
		{
			padding: 0;
			margin: 0;
		}
	}

	input
	{
		width: 100%;
		background-color: $fill-color-primary;
		padding:16px 14px;
		border-radius: 28px;
		border:none;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;

		color: $text-color-secondary;
		margin-bottom:8px;







		&::-webkit-search-cancel-button
		{
			display: none;
		}


		& + .description
		{
			display: block;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			color: $text-color-secondary;
			margin-bottom: 20px;
			&>span
			{
				display: initial;
			}
			.error
			{
				display: none;
			}

			&+.indicator
			{
				
				button
				{
					&>*
					{
						display: none;
					}	
					.delete
					{
						display: initial;
					}
				}
			}

		}

		&[type="search"] + .description +.indicator 
		{
			&:before
			{
				position: absolute;
				content:" ";
	/*			border:2px solid red;*/
				height: 24px;
				left:-12px;
				border-left: 1px solid $dividers-color;
			}
			& > button
			{
				& > *{display: none}
				& > .search
				{
					display: initial;
				}
			}
		}

		&:invalid
		{
			caret-color:$brand-color-primary;
			&+.description
			{
				
				&>span
				{
					display: none;
				}
				.error
				{
/*					color:$brand-color-primary;	*/ /*Aileen did not like red message*/
					display: initial;
				}
				&+.indicator 
				{

					
					button
					{	.error
						{
							display: initial;
						}
						.delete
						{
							display: none;
						}
					}
				}
			}
		}
	}


	/*&.error
	{
		input[type="text"]
		{
			caret-color:$brand-color-primary;
			&+span
			{
				color:$brand-color-primary;	
			}
		}
		
		
	}*/



}