
@import "~bootstrap/scss/bootstrap";
@import '_constants.scss';


.text-content-page
{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;


	.menu
	{
		background-color: white;
		position: fixed;
		border: 1px solid red;
		top:0;
		left: 0;
		right: 0;
		padding:24px;
		display:flex;

		align-items:center;
		justify-content:right;
		column-gap:20px;

		a.home-logo 
		{
			display:flex;
			align-items:center;
			column-gap:16px;
			margin-right:auto;


			svg
			{
				height: 48px;
				width: 48px;
			}
			span
			{

				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				padding-top: 12px;
			}
		}
		.collapse-items
		{
			display:flex;
			flex-direction:row;
			justify-content:right;
			column-gap:20px;
			.menu-item
			{
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				padding-top: 12px;
			}	
			@include media-breakpoint-down(sm) {

				border:1px solid red;
				flex-direction: column;

				position: fixed;
				left: 0;
				right: 0;
				top:100px;
				background-color: white;
				padding:20px;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;

			}
		}
		
	}


	.main-content
	{
		padding-top:108px;
		max-width: 656px;
		width: 100%;
		
		padding-left: 16px;
		padding-right: 16px;
	}

}