@import "~bootstrap/scss/bootstrap";

@import "./../../../components-css/_constants.scss";


.upload-modal
{
	.modal-dialog.step-0
	{
/*		border:3px solid lightcyan;*/
		width: auto;
		@include media-breakpoint-up(sm)
		{
			top:20vh;
		}

		.modal-content
		{
			margin:0 auto;
			width: 70vw;
			height: 60vh;
			background-color: yellow;


			@include media-breakpoint-down(sm)
			{
				width: 100vw;
				height: auto;
				min-height: 200px;
			}

			.modal-header h3{
				display: none;
			}
			
			
			.modal-body
			{
				display: flex;
				text-align: center;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				@include media-breakpoint-down(sm)
				{
					padding-bottom:40px;

				}

				
			}

		}
	}
		
			
}