

@import "~bootstrap/scss/bootstrap";

.double-slider{



	.rec-carousel-wrapper
	{
		height: 100vh;
	/*	margin-bottom:1em;*/
		@include media-breakpoint-down(sm) {
			height: calc(100vh - 20px);
			height: calc(100dvh - 20px);
			margin: 0;
		}
		width: 100%;
		max-width:500px;
		.rec-slider-container
		{
	/*		height: auto;*/
			height: 100%;
			overflow: visible;
			@include media-breakpoint-down(sm) {
				overflow: hidden;
			}
			
			margin:0;

			.rec-carousel-item
			{
		/*		background-color:gray;*/
				height: 100vh;
				@include media-breakpoint-down(sm) {
					height: calc(100vh - 20px);
					height: calc(100dvh - 20px);
				}
				
				.rec-item-wrapper{
					width: 100%;
						height: 100%;
					.slide{
						display: flex;
						justify-content: center;
						align-items: center;
						span{
							font-size:80px;
						}
						width: 100%;
						height: 100%;
						background-color: lightblue;
						border:2px solid black;
						
					}
				}
			}
		}

		.rec-pagination
		{
			position: absolute;
			bottom: 10px;
		}
		.rec-carousel 
		{
			width: 100%;
			height: 100%;
			position:relative;
			
		}

		.rec-arrow-left
		{
			position:absolute;
			left:10px;
			z-index:999;
		}

		.rec-arrow-right
		{
			position:absolute;
			right:10px
		}
	}

}

bodyx
{
	position: relative;
	width: 100vw;
	@include media-breakpoint-down(sm) {
		overflow: hidden;
	}
	
/*    padding-bottom: env(safe-area-inset-bottom);*/
}
