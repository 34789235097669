@import './_constants.scss'	;

	.user-bar
	{
		display:flex;
		flex-direction:row;
		column-gap:23px;
		margin-bottom:20px;
		min-height:64px;
		.item
		{
			display:flex;
			flex-direction:column;
			align-items:center;
			position:relative;
			max-width:48px;
/*			overflow:hidden;*/
/*			text-overflow: ellipsis;*/
/*			white-space: nowrap;*/
			.avatar
			{
				width: 48px;
				height: 48px;
				overflow: hidden;
				border-radius: 24px;
				img
				{
					width: 100%;
				}

			}
			.remove
			{
/*				border:2px solid red;*/
				position: absolute;
				left:32px;
				top:1px;
				width: 16px;
				height: 16px;
				border-radius: 8px;
				padding: 0;
				background-color: $brand-color-primary;
				display:flex;
				flex-direction: row;
				justify-content:center;
				align-items:center;
				svg
				{
/*					position: absolute;*/
				}
			}
			.name
			{
				max-width: 64px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		
	}
