@import "~bootstrap/scss/bootstrap";
@import "./../../../components-css/_constants.scss";


.post-component
{
	width: 100%;
	
	&.chat-open
	{
	/* 
		this is to prevent odd jiggling of all the sliders on mobile
		when chat is open
	*/
		@include media-breakpoint-down(sm) 
		{
			position: fixed;
			top:0;
			z-index: 9999;
		}
	}


	/*
		height of a post is 
		screen height - bottom menu
	*/
	
	@include media-breakpoint-up(sm) 
	{
		height: calc(100vh - 68px);
	}
	@include media-breakpoint-down(sm) 
	{
/*		height: calc(100dvh - 128px);*/
		height: calc(100vh - 128px);
			
	}



/*
	@supports (height: 100dvh) {
	  	height: calc(100dvh - 68px);
		
	}*/

	/*
		this is roughly iphone size
	*/
	min-height:500px;


/*	height:889px;*/ /* 16:9 */
/*	border:1px solid red;*/
	@include media-breakpoint-up(sm)
	{
		border-radius: 12px;
	}
	background-color: $text-color-primary;
/*	margin-top:1em;*/
	@include media-breakpoint-up(sm) 
	{
		margin-bottom:1em;
	}
	
/*	overflow: hidden;*/
	position: relative;


	.carousel-wrapper{
		width: 100%;
		height: 100%;

		/*@include media-breakpoint-down(sm) {
			height: calc(100vh - 128px);
			border:2px solid red;
			margin: 0;
		}*/


		@include media-breakpoint-up(sm)
		{
			border-radius: 12px;
		}
		overflow: hidden;

/*		border:4px solid red;*/
		position:relative;

		.rec-carousel
		{
/*			height: 100%;*/
/*			width: 100%;*/
/*			border:4px solid green;*/

/*			position: absolute;*/
		    /*top: 50%;
		    tr*/
/*		    ansform: translateY(-50%);*/
/*		    inset:0;*/

/*		    height: calc(100vh - 68px);*/
    
			.rec-slider-container
			{
/*				border:2px solid blue;*/
				margin:0;
				.rec-slider{
/*					border:2px solid red;*/
					inset:0;

					.rec-carousel-item
					{
						
	/*					background-color: green;*/
	/*					border:2px solid red;*/
						width: 500px;
						max-width: 100vw;
/*						height: 100%;*/



						height: calc(100vh - 68px);
						@include media-breakpoint-down(sm) 
						{
							height: calc(100vh - 128px);
						}


						.rec-item-wrapper
						{
							height: 100%;


						}
					}
				}
			}
		}

		.rec-pagination
		{
			position: absolute;
/*			border: 2px solid red;*/
			bottom: 20px;
			left: 50%;
			transform: translateX(-50%);
			.rec-dot
			{
				width: 8px;
				height: 8px;
				border-radius: 4px;
				padding:0;
				background-color: rgba(255,255,255,0.6);
				box-shadow: none;
				margin:2px;
				&.rec-dot_active
				{
					background-color: rgba(255,255,255,1);
				}
			}
		}
		.rec-arrow
		{
			position: absolute;
			top:50%;
			z-index: $carousel-left-right-zindex;
			transform: translateY(-50%);
			@include media-breakpoint-down(sm) 
			{
				top:40%;
				display: none;
			}

			&.rec-arrow-edge
			{
				opacity: 0.5;
			}
			&.rec-arrow-prev
			{
				left:20px;
			}
			&.rec-arrow-next
			{
				right:20px;
			}
		}
		
	}

}