

@import "~bootstrap/scss/bootstrap";
@import '_constants.scss';

/*on mobile hide bottom nav when a dialog is open*/

body.modal-open
{
  .bottom-nav.hide-on-modal
  {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}


.bottom-nav
{
  z-index: $bottom-nav-zindex;
  position: fixed;
  bottom:0;
  left: 0;
  width: 100%;
  padding:12px 0;
  background-color: rgba(255,255,255,0.8);
  border-top:1px solid #D9D9D9;
/*  height: 70px;*/
/*  opacity: 0.8;*/

  @include media-breakpoint-down(md) {
      background-color: black;  
      opacity: 1;
      padding-top:4px;
      padding-bottom:4px;    
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
   align-items: center;
  column-gap: 70px;


  .menu-item
  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    @include media-breakpoint-up(md) {
       .mobile-only
       {
        display: none;
       }
    }
    @include media-breakpoint-down(md) {

      .desktop-only
      {
        display: none;
      }


      span
      {
        color: white;    
      }

      svg
      {
        height: 24px;
        width: 24px;
      }
      &.add-link svg{
        height: 40px;
        width: 40px;
      }
    }


/*    border:1px solid red;*/
    &.bubble:after
    {
      content:"";
      width: 8px;
      height: 8px;
      border-radius: 4px;
      position: absolute;
      background-color: $brand-color-primary;
      right:0px;
      top:0;

    }



  }
}








