.add-people-modal
{
	.modal-footer
	{
		display: flex;
		flex-direction: row;
		justify-content: center;

	}
	

	.menu-wrapper
	{
		margin-top:-25px;
		position:relative;
/*		margin-left:40px;*/
/*		margin-right:16px;*/
	}

}