@import '_constants.scss';


/**
 * This styles the dropdown with auto-complete user search
 * 
 * */
.auto-complete
	{
		position: absolute;
		left:0;
		top:100%;
		z-index: $autocomplete-zindex;

		width:100%;



		/* Search results */

		padding: 16px;
		display: flex;
		flex-direction: column;
		row-gap: 8px;
		background: #FFFFFF;
		border: 1px solid #D9D9D9;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
		overflow:hidden;


		.auto-complete-item
		{

			font-weight: 600;
			font-size: 16px;
			line-height: 20px;
			color: #1E1E1E;
			cursor:pointer;
			display:flex;
			flex-direction:row;
			column-gap:12px;
			align-items:center;
/*			width: 100%;*/
			max-width:100%;
			overflow: hidden;
			text-overflow:ellipsis;
			align-content:stretch;

			&.active
			{
				background-color: $fill-color-primary;
			}

			.avatar
			{
				flex-grow: 0;
				flex-shrink: 0;
				width: 48px;
				height: 48px;
				overflow: hidden;
				border-radius: 24px;
				background-color: gray;
				img
				{
					width: 100%;
					height: 100%;
				}
			}

			.name
			{
				flex-shrink: 0;
				flex-grow: 0;
			}
		}

	}	