@import '_constants.scss';


.chat-preview
{
/*  border: 1px solid red;*/
/*  height: 96px;*/
  position: absolute;
  bottom: 70px;
  left: 20px;
  right: 80px;
/*  left:20px;*/
  cursor: pointer;
/*  background-color: red;*/
  z-index: $chat-preview-zindex;
  .history-item
  {
    position: relative;
/*      border:1px solid blue;*/
      padding-top:7px;
      padding-bottom:7px;
/*      border-bottom: 1px solid gray;*/
  
      display: flex;
      column-gap:12px;

   /*.avatar 
    {
      width: 48px;
      img{
        width: 48px;
        height: 48px;
        border-radius: 24px;
      }
    }*/

    .message
    {
      width: 100%;
/*      border:1px solid red;*/
/*      display:flex;*/
/*      flex-direction: row;*/


/*          align-self: stretch;*/
      .sender, .reply,  .date
      {
          /* Sara */
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
/*            color: #757575;*/
        color: white;
        padding-bottom: 4px;
/*            float: left;*/
/*            clear: both;*/
      }

      .reply
      {
        text-decoration: underline;
      }

      .body
      {
/*            float: left;*/
/*            clear: left;*/
        color:white;
/*            color: #757575;*/
        text-overflow: ellipsis;
        max-height: 23px;
        overflow: hidden;
        padding-right: 20px;
        padding-bottom: 4px;
        font-size: 13px;
      }

      .sender, .reply, .date,.body
      {
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.9);
      }
    }
  }


}

.chat-wrapper
{
/*  border:2px solid green;*/


/*  */
  
  top:0;
  left: 0;
  right: 0;
  bottom:0;
  overflow: hidden;
  position: absolute;

   min-height:200px;
  .chat-container
  {
    border:1px solid #D9D9D9;
    overflow: hidden;
    top:50%;
    left: 0;
    bottom: 0;
    transform: translateY(100%);  
    right: 0;
    background-color: white;
    z-index:$chat-container-zindex; /*careful here: 999 is controls overlay, chat is on top with 9999*/
    position: absolute;

   
    /*border-top-left-radius: 10px;
    border-top-right-radius: 10px;

     border-top-left-radius: 10px;
    */border-radius: 10px;


    padding-bottom:110px;
    padding-top: 35px;
    transition:transform 0.5s ease;

    

    &.open
    {

      transform: translateY(0%); 
     
    }



    .emoji-list
    {
      list-style: none;
      padding:0;
      display: flex;
      justify-content: space-between;

      button
      {
        padding:2px;
        border:none;
        background: none;
        font-size: 24px;
      }
    }
   

    & > .input-form
    {
      border-top: 1px solid #D9D9D9;
    }
    
    .input-form
    {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 8px;
      padding-bottom: 14px;
      display: flex;
      column-gap: 12px;
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;

      &.no-padding
      {
        padding-left: 0;
        padding-right: 0;
        position: initial;
      }


/*      border:2px solid red;*/
/*      display: flex;*/
      .emoji-wrapper
      {
        width: 100%;

      }

      .input-field
      {
        display: flex;
        position: relative;
        column-gap:12px;

        .avatar, .avatar-image
        {
            width: 42px;
            height: 42px;
            border-radius: 21px; 
/*            margin-right: 12px;*/
        }

        .msg-input
        {

          border: none;
    /*      border: 1px solid red;*/
          width: 100%;
          height: 44px;
          border-radius: 22px;
          background-color: #F1F1F2;
          padding-right: 44px;
          padding-left: 12px;

          font-weight: 400;
          font-size: 14px;
          line-height: 24px;

          color: #757575;




          &+.spinner-wrapper
          {
             position: absolute;
             right: 0;
          }
          &+.submit-button
          {
            position: absolute;
            background: none;
  /*          border: 1px solid red;*/
            border: none;
            padding-left: 4px;
            padding-right: 4px;
            right: 8px;
            top:9px;
/*            position: relative;*/
/*            right: 20px;*/
/*            margin-left: -49px;*/
          }

          

        }
      }
      .spinner-wrapper .spinner
      {
        position: absolute;
        right: 12px;
        top:14px;
        width: 20px;
        height: 20px;
/*            border: 1px solid red;*/
      }
    }





    .header
    {
      position: absolute;
      top:0;
      width: 100%; 
      text-align: center;
  /*    height: 40px;*/
      padding-bottom:10px;
      padding-top:10px;
      background-color: white;

      h3{
        font-size:14px;
      }
      button.close
      {
        position: absolute;
        right: 12px;
        top:10px;
        line-height: 1em;
        border: none;
        background: none;
        padding:5px;
  /*      font-size: 2em;*/
      }
    }
    .chat-history
    {
      position: relative; /*very very very important for proper chat scrolling*/
      padding-right:12px;
      padding-left:12px;
      .avatar 
      {
        width: 48px;
        img{
          width: 48px;
          height: 48px;
          border-radius: 24px;
        }
      }
      

  /*    border:2px solid blue;*/
      width: 100%;
      height: 100%;
/*      margin-bottom: 100px;*/

      overflow: scroll;
      .history-item
      {
  /*      border:2px solid blue;*/
        position: relative;
  /*      border:1px solid blue;*/
        padding-top:7px;
        padding-bottom:7px;
  /*      border-bottom: 1px solid gray;*/
    
        display: flex;
        column-gap:12px;

        & > div
        {
  /*        border:1px solid red;*/
  /*        flex:1;*/

          &.avatar
          {
            width: 48px;
          }


          &.message
          {
            
            width: 100%;

            .details
            {
              display: flex;
              flex-direction: row;
              column-gap:40px;
              
              .date, .reply, .reply a
              {


                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
              /* identical to box height, or 133% */

                color: #757575;
              }
            }

  /*          align-self: stretch;*/
            .sender
            {
                /* Sara */
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              color: #757575;
              padding-bottom: 4px;
  /*            float: left;*/
  /*            clear: both;*/
            }

            .body
            {
  /*            float: left;*/
  /*            clear: left;*/
              padding-right: 40px;
              padding-bottom: 4px;
              font-size: 13px;
            }
            .react
            {
              position: absolute;
              top:28px;

              right: 0;
              button
              {
                padding: 0;
                background: none;
                border:none;
              }

              .number
              {
                padding-left: 6px;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;

                color: #757575;

              }
            }
            

            .reply-form
            {
              display: flex;
              flex-direction: row;
/*              background-color: red;*/
            }

            .replies
            {
/*              border:1px solid red;*/
              margin-top:12px;
              .avatar
              {
/*                width: 24px;*/
                width: 24px;
                  height: 24px;
                 .avatar-image,
                .avatar-image img{
                  width: 24px;
                  height: 24px;
                  border-radius: 12px;
                }
              }
            }
          }
        }


      }
    }

  }
}
