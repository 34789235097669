@import "~bootstrap/scss/bootstrap";


.feed-container
{
	max-width:500px;
	width: 100%;
	margin: 0 auto;

	@include media-breakpoint-up(sm) 
	{
		margin-bottom: 68px;

	}


/*	border:2px solid red;*/
/*	height: calc(100vh - 68px);*/
/*	overflow-y: hidden;*/



	.rec-carousel-wrapper.vertical
	{
/*		border:1px solid red;*/
/*		height: initial;*/
		height: 100vh;
		@include media-breakpoint-down(sm) {
			height: calc(100vh - 128px);
			margin: 0;
		}
	/*		border:2px solid black;*/
	/*		max-width: 500px;*/
		width: 100%;
/*		max-width:500px;*/


		& > .rec-carousel
		{

			& > .rec-slider-container > .rec-slider > .rec-swipable > .rec-carousel-item:last-child
			{
				@include media-breakpoint-up(sm) {
					
					margin-bottom: 68px;
/*					border:1px solid green;*/
				}
			}

			& > .rec-arrow
			{
/*				position: absolute;*/
				/*top:0;
				left:0;*/
				/*width:0;
				height:0;*/
				/*display: none;*/
			}
			& > .rec-slider-container
			{
/*				border:4px solid yellow;*/
				margin: 0;
				overflow: visible;
				@include media-breakpoint-down(sm) {
					overflow: hidden;
				}

				.rec-item-wrapper
				{

					/* this is needed to keep content-actions visible on desktop */
					overflow: visible;

				}
			}
		}
	}
}



body
{
	position: relative;
/*	width: 100vw;*/
	@include media-breakpoint-down(sm) {
/*		overflow: hidden;*/
		padding-bottom: env(safe-area-inset-bottom);
		
	}
}
/*    padding-bottom: env(safe-area-inset-bottom);*/
