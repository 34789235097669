/*
	todo: make sure we use constants in all other css files
*/

$text-color-primary: #1E1E1E;
$text-color-secondary: #757575;

$dividers-color: #D9D9D9;

$fill-color-primary:#F1F1F2;
$fill-color-secondary: #FFFFFF;

$brand-color-primary: #FE505B;
$brand-color-secondary: #25D6DE;

$font-family: Montserrat, Arial;








$debug-zindex: 99999999;


$modal-zindex:999999 ;



$content-actions-zindex: 99;
$flash-message-zindex: 99999999;
$carousel-left-right-zindex:99;
$autocomplete-zindex:9999;

$bottom-nav-zindex:99999;
$top-nav-zindex:99999;




$chat-preview-zindex:999;

$chat-container-zindex:9999; /*careful here: 999 is controls overlay, chat is on top with 9999*/

$invite-widget-zindex: 999;