@import "~bootstrap/scss/bootstrap";
@import "./_constants.scss";



.dm-view
{
	padding-top:100px;
/*	padding-bottom: 80px;*/
	display: flex;
	justify-content: center;

	@include media-breakpoint-down(sm)
	{
		padding-left: 16px;
		padding-right: 16px;
	}


	.mid-row

	{
		width: 100% ;
		max-width: 500px;
		display: flex;
		flex-direction: column;
		row-gap: 8px;

		
	}

	.filter-control
	{
/*		width: 100%;*/
		
/*		margin-bottom: 40px;*/

		padding-bottom: 40px;
		@include media-breakpoint-down(sm)
		{
			padding-bottom: 20px;
		}
		
	}


	.dm-item 
	{


		transition: height 0.5s ease-in;
/*		overflow:hidden;*/
/*		height:94px;*/
		&.visible
		{
/*			border:2px solid red;*/
/*			height:94px;*/

		}

		&.not-visible
		{
			height: 0px;
		}





		a
		{
			display: flex;
			justify-content: flex-start;
			flex-wrap: nowrap;
/*			margin-bottom: 20px;*/
			/*.avatar
			{
				flex-shrink: 0;
				flex-grow: 0;
				width: 64px;
				height: 64px;
				border-radius: 32px;
				overflow: hidden;
				img
				{
					width: 100%;
					height: 100%;
				}
			}*/

			.message{
				padding-left: 20px;
				padding-right:20px;
				display: flex;
				flex-shrink: 5;
				flex-grow: 5;
				flex-direction: column;
				.sender-name
				{
					font-weight: 400;
					font-size: 16px;
					line-height: 20px;
					color: #1E1E1E;
					padding-bottom: 8px;

					&.new
					{
						font-weight: 600;
					}
				}

				.body
				{
/*					border:1px solid red;*/

				}
				.body, .body > .since
				{
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					color: #757575;
				}


			}

			.thumbnail
			{
				flex-shrink: 0;
				flex-grow: 0;
				margin-left: auto;
				height: 74px;
				width: 50px;
				border-radius: 4px;
				position: relative;
				overflow: hidden;
				background-color: black;
				img
				{
					position: absolute;
					top:50%;
					width: 100%;
					transform: translateY(-50%);

				}
			}
		}
	}
}