.animation-demo
{
	.video-frame
	{
		background-color: darkgray;

		background: conic-gradient(

			white 90deg,
			darkgray 90deg 180deg,
			white 180deg 270deg,
			darkgray 270deg
		);

		background-repeat: repeat ;
		background-size: 30px 30px ;
		background-position: top left ;





		overflow:hidden;
		height: 947px; /* portrait video */
    	width: 507px;
    	color:red;
    	margin-top:20px;
    	position: relative;
    	video
    	{
/*    		z-index: -1*/
    	}

    	canvas
    	{
    		position: absolute;
    		top:0;
    		bottom: 0;
    		left: 0;
    		right: 0;
    		z-index: 99;
    	}
	}	
}