@import "~bootstrap/scss/bootstrap";

@import "./../../../components-css/_constants.scss";



.upload-modal
{

/*	border:20px solid red;*/
	.modal-dialog{
		overflow: hidden;
		position: relative;
		margin-left: auto;
		margin-right: auto;

		
		.modal-content{
/*			height:500px;		*/

			/*.modal-header
			{
				border-bottom: none;
				text-align: center;
				padding-left:40px;
				padding-right: 40px;
				padding-bottom: 28px;
				padding-top: 28px;
				h3{
					padding:0;
					margin: 0;
				}
			}*/
				.modal-body{
					
/*					background-color:yellow;*/
/*					height: auto;*/
					.media-grid-wrapper
					{
/*						height: 30%;*/
					
						.add-file
						{
							width: 100%;
							height: 100%;
							background-color: $fill-color-primary;
							display: flex;
							justify-content: center;
							align-items: center;
							cursor: pointer;
						}

						
						
						

						.media-item .remove-butt
						{
/*							background-color: red;*/
							padding:0;
							position: absolute;
							right: 7px;
							top:7px;
							line-height: 10px;

						}

					}
					.form-description
					{
/*						border:1px solid red;*/
/*						padding-top:20px;*/
						height: auto;
						margin-top:12px;

					}

					.recents
					{
						display:flex;
						flex-direction:row;
						column-gap:23px;
						margin-bottom:20px;
						.item
						{
							display:flex;
							flex-direction:column;
							align-items:center;
							.avatar
							{
								width: 48px;
								height: 48px;
								overflow: hidden;
								border-radius: 24px;
								img
								{
									width: 100%;
								}
							}

							.name
							{

							}
						}
						
					}



				}
				
				.modal-footer
				{
					text-align: center;
					justify-content: center;
				}

		}
		
	}
	
}