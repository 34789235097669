@import "./../../../components-css/_constants.scss";
@import "~bootstrap/scss/bootstrap";

.video-player
{
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $text-color-primary;
	

	@include media-breakpoint-up(sm)
	{
		border-radius: 12px;
	}

	
	video
	{
		

		@include media-breakpoint-down(sm)
		{
			width:100%;
		}

		@include media-breakpoint-up(sm)
		{
			width:100%;
		}
/*		border:1px solid red;*/

	}

	.controls-overlay
	{
		position: absolute;
		left: 0;
		top:0;
		bottom: 0;
		right: 0;
		
		.play-button
		{
			position: absolute;
			top:50%;
			left: 50%;
			transform: translate(-50%,-50%);
			opacity: 0.8;
		}

		.reactions
		{
			position: absolute;
			bottom:180px;
			/*              border:2px solid red;*/
			height: 50px;
			border-radius: 25px;
			padding: 0 1em;
/*			background-color: rgba(255,255,255,0.3);*/
			background-color: rgba(0,0,0,0.4);
			width: auto;
			left:50%;
			transform: translateX(-50%);
			/*              margin: 0 auto;*/
			/*              left: 120px;*/
			/*              right: 120px;*/
			ul
			{

				margin: 0;
				padding: 0;
				list-style: none;
				display: flex;
				justify-content: space-between;
				a{
					font-size: 2em;
				  	padding: 0 5px;
				  	line-height: 50px;
				}
			}
		}


		&:hover .timeline,
		&:hover .timeline.timeline-hidden
		{
			display: initial;
		}

	    .timeline
        {
        	&.timeline-hidden
        	{
        			display: none;
        	}
          position: absolute;
/*            bottom: 50px;*/
          bottom:55px;
          left: 20px;
          right: 20px;
          height: 4px;
          border-radius: 2px;
          background-color: rgba(255,255,255,0.3);
          cursor: pointer;
          @include media-breakpoint-down(sm)
          {
          	left:80px;
          	right:80px;
          }
          .reaction_indicator
          {
            position: absolute;
            top:-9px;
            margin-left: -10px;
/*                border:3px solid red;*/
          }
          .current-time
          {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top:8px;
/*                border:2px solid red;*/
            /* 00:15 */


            font-weight: 600;
            font-size: 10px;
            line-height: 12px;

            color: #FFFFFF;

            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);




          }
          .total-time
          {
            position: absolute;
            right: 0;
            top:8px;
            color:white;
            font-style: normal;
            font-weight: 200;
            font-size: 10px;
            line-height: 12px;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);




          }
          .progress
          {
            position: absolute;
            content:" ";
            left: 0;
            width: 0%;
            height: 100%;
            display: block;
            background-color: white;
/*                border:1px solid black;*/
          }
        }
	}
	


}