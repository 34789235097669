		
@import "~bootstrap/scss/bootstrap";
@import "./../../../components-css/_constants.scss";


.content-actions 
{
	position: absolute;
	z-index: $content-actions-zindex;
	right: 16px;
	bottom: 0px;
	@include media-breakpoint-down(sm)
	{
		bottom: 30px;
	}



	
	@include media-breakpoint-up(sm)
	{
		right: -68px;
	}


	ul
	{
		list-style: none;
		padding:0;
		margin: 0;
		display: block;
/*		margin-bottom: 99px;*/
		@include media-breakpoint-up(sm)
		{
			margin-bottom: 0;
		}
		li{
			display: flex;
			flex-direction: column;
			justify-content: center;


			margin-top: 20px;
			button
			{
				display: flex;
				align-items: center;
				justify-content: center;
/*				border:1px solid red;*/
				background: none;
				border:none;
				color: gray;
				padding:0;
				@include media-breakpoint-up(sm)
				{
						width: 48px;
						height: 48px;
						border-radius: 24px;
						background-color:  #F1F1F2;
				}
	 

	 			.show-on-mobile,.show-on-desktop
	 			{
	 				position: relative;
	 			}
				@include media-breakpoint-down(sm)
				{
					.show-on-mobile
					{
						display: initial;
					}
					.show-on-desktop
					{
						display: none;
					}
				}

				@include media-breakpoint-up(sm)
				{
					.show-on-mobile
					{
						display: none;
					}
					.show-on-desktop
					{
						
						display: initial;
					}
				}





				svg
				{
					width: 24px;
					height: 24px;
				}

			 
				



				&+span
				{
					/*color: white;
					display: block;*/
					font-weight: 700;
					align-self: center;
					font-size: 12px;
					@include media-breakpoint-down(sm)
					{
						& {
							font-size: 12px;
							color:white;
						}
					}

				}
			}
		}
	}
}