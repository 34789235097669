@import "~bootstrap/scss/bootstrap";
@import "./../../../components-css/constants.scss";




.invite-prompt
{
	padding:12px 20px;
	position: absolute;
	bottom:0;
	left: 0;
	right: 0;
	min-height: 100px;
	border-radius: 12px;
	background-color: white;
/*			border:1px solid red;*/
	
	border:1px solid #D9D9D9;
	.header
	{
		padding-top:12px;
		padding-bottom:20px;
		text-align: center;
		@include media-breakpoint-down(sm) 
		{

			display: none;
		}
	}
	.body{
		position: relative;
		padding-left: 60px;
		min-height: 48px;
		@include media-breakpoint-down(sm) 
		{
			padding-left: 0;
			display: flex ;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		/* move this element (user avatar + name + message)
		to a component				*/
		.avatar
		{
			left: 0;
			position: absolute;
			width: 48px;
			height: 48px;
			border-radius: 24px;
			@include media-breakpoint-down(sm) 
			{

				width: 64px;
				height: 64px;
				border-radius: 32px;
				position: initial;
			}
		}

		.name
		{


			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 600;
			font-size: 12px;
			line-height: 16px;
			/* identical to box height, or 133% */

			color: #757575;

			@include media-breakpoint-down(sm) 
			{



				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 19px;
				/* identical to box height, or 136% */

				color: #1E1E1E;



			}


		}

		.message{
			/* I would like to collaborate with you */

			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			padding-bottom:4px;

			/*@include media-breakpoint-down(sm) 
			{

				display: none;
			}*/



		}

		.date
		{
			font-weight: 500;
            font-size: 12px;
            line-height: 16px;
              /* identical to box height, or 133% */

            color: #757575;
		}

	}
	.footer{
		padding-top: 20px;
		display: flex;
		justify-content: center;
		column-gap: 12px;
	}
	.button
	{
		min-width:50% ;

	}
}